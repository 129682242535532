class LayoutGridHelpers {
    /**
     * Clears the temporary guides from the canvas
     * @param format The current format
     */
    static clearTemporaryGuides = (): void => {
        const canvas = document.querySelector('.template-designer__editor__temporary-guides') as HTMLCanvasElement;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;

        context.clearRect(0, 0, canvas.width, canvas.height); // Clear existing content
    };
}

export { LayoutGridHelpers };
