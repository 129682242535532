import get from 'lodash/get';
import unset from 'lodash/unset';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Translation from 'components/data/Translation';
import { defaultAudioProperties } from '../config/layer-properties/audio-properties';
import {
    buttonArrowProperties,
    buttonContainedContainerProperties,
    buttonContainedArrowImageProperties,
    buttonContainedArrowProperties,
    buttonContainedTextProperties,
    buttonContainedProperties,
    buttonContainedSquareArrowContainerProperties,
    buttonContainedSquareArrowImageProperties,
    buttonContainedSquareArrowTextProperties,
    buttonContainerArrowContainedProperties,
    buttonContainerArrowOutlinedProperties,
    buttonContainerArrowProperties,
    buttonOutlinedContainerProperties,
    buttonOutlinedArrowImageProperties,
    buttonOutlinedArrowProperties,
    buttonOutlinedTextProperties,
    buttonOutlinedProperties,
    buttonOutlinedSquareArrowContainerProperties,
    buttonOutlinedSquareArrowImageProperties,
    buttonOutlinedSquareArrowTextProperties,
    buttonTextArrowContainerProperties,
    buttonTextArrowImageProperties,
    buttonTextProperties
} from '../config/layer-properties/button-properties';
import { defaultContainerProperties } from '../config/layer-properties/container-properties';
import { defaultProperties } from '../config/layer-properties/default-properties';
import { backgroundImageProperties, defaultImageProperties, logoImageProperties } from '../config/layer-properties/image-properties';
import { defaultLottieProperties } from '../config/layer-properties/lottie-properties';
import { circleProperties, lineProperties, rectangleProperties, rectangleRoundedProperties } from '../config/layer-properties/shape-properties';
import {
    bodyProperties,
    captionProperties,
    defaultTextContainerProperties,
    defaultTextProperties,
    headlineProperties,
    overlineProperties,
    smallBodyProperties,
    sublineProperties
} from '../config/layer-properties/text-properties';
import { backgroundVideoProperties, defaultVideoProperties } from '../config/layer-properties/video-properties';
import Format from '../types/format.type';
import Layer, { PropertyKeys } from '../types/layer.type';
import LayerProperties, {
    AllProperties,
    AudioProperties,
    ImageProperties,
    LottieProperties,
    ShapeProperties,
    ContainerProperties,
    TextProperties,
    VideoProperties
} from '../types/layerProperties.type';
import Template, { UnitOptions, State, View } from '../types/template.type';
import LayerHelpers from './layer.helpers';
import { getTemplateData } from './data.helpers';
import { TemplateVersionHelpers } from './template-version.helpers';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { AnimationOptions } from '../types/animation.type';
import FrameType from '../types/frameTypes.type';
import { TimelineHelpers } from './timeline.helpers';
import { FontHelpers } from './font.helpers';

class LayerPropertiesHelpers {
    /**
     * Get the default layer properties based on layer type and layer sub type.
     * @param layerType - The type of the layer.
     * @param propertyKey - The propertyKey to get the styling from.
     * @returns The corresponding layer properties.
     */
    static getDefaultProperties(
        layerType: 'audio' | 'image' | 'video' | 'text' | 'shape' | 'container' | 'lottie' | Layer['type'],
        propertyKey?: PropertyKeys
    ): AudioProperties | ImageProperties | VideoProperties | TextProperties | ShapeProperties | ContainerProperties | LottieProperties | AllProperties | null {
        let properties: AllProperties | null = null;

        if (!propertyKey) {
            switch (layerType) {
                case 'container':
                    properties = defaultContainerProperties;
                    break;
                case 'shape':
                    properties = defaultProperties;
                    break;
                case 'image':
                    properties = defaultImageProperties;
                    break;
                case 'text':
                    properties = defaultTextProperties;
                    break;
                case 'video':
                    properties = defaultVideoProperties;
                    break;
                case 'audio':
                    properties = defaultAudioProperties;
                    break;
                case 'lottie':
                    properties = defaultLottieProperties;
                    break;
            }
        } else {
            const propertyKeyOptions: Record<PropertyKeys, AllProperties> = {
                text_container: defaultTextContainerProperties,
                headline: headlineProperties,
                subline: sublineProperties,
                body: bodyProperties,
                small_body: smallBodyProperties,
                overline: overlineProperties,
                caption: captionProperties,
                background_image: backgroundImageProperties,
                logo_image: logoImageProperties,
                background_video: backgroundVideoProperties,
                rectangle: rectangleProperties,
                circle: circleProperties,
                line: lineProperties,
                rectangle_rounded: rectangleRoundedProperties,
                button_contained: buttonContainedProperties,
                button_outlined: buttonOutlinedProperties,
                button_text: buttonTextProperties,
                button_contained_container: buttonContainedContainerProperties,
                button_contained_text: buttonContainedTextProperties,
                button_contained_arrow_image: buttonContainedArrowImageProperties,
                button_outlined_container: buttonOutlinedContainerProperties,
                button_outlined_text: buttonOutlinedTextProperties,
                button_outlined_arrow_image: buttonOutlinedArrowImageProperties,
                button_text_container: buttonTextArrowContainerProperties,
                button_text_arrow_image: buttonTextArrowImageProperties,
                button_contained_square_arrow_container: buttonContainedSquareArrowContainerProperties,
                button_contained_square_arrow_text: buttonContainedSquareArrowTextProperties,
                button_contained_square_arrow_image: buttonContainedSquareArrowImageProperties,
                button_outlined_square_arrow_container: buttonOutlinedSquareArrowContainerProperties,
                button_outlined_square_arrow_text: buttonOutlinedSquareArrowTextProperties,
                button_outlined_square_arrow_image: buttonOutlinedSquareArrowImageProperties,
                button_arrow_contained_container: buttonContainerArrowContainedProperties,
                button_contained_arrow: buttonContainedArrowProperties,
                button_arrow_outlined_container: buttonContainerArrowOutlinedProperties,
                button_outlined_arrow: buttonOutlinedArrowProperties,
                button_arrow_container: buttonContainerArrowProperties,
                button_arrow: buttonArrowProperties
            };

            properties = propertyKeyOptions[propertyKey];
        }

        // We need to clone the properties object otherwise the default properties are overwritten and with the next call of this function it will use those values instead of the default properties.
        properties = cloneDeep(properties);

        if (properties) properties = this.overwriteUnit(properties);
        if (properties) properties = this.overwriteDefaultProperties(properties);

        return properties;
    }

    /**
     * Overwrite the unit with that of the default unit.
     * @param layerProperties - The layer properties to overwrite.
     * @returns The layer properties with the overwritten unit.
     */
    private static overwriteUnit(
        layerProperties:
            | AudioProperties
            | ImageProperties
            | VideoProperties
            | TextProperties
            | ShapeProperties
            | ContainerProperties
            | LottieProperties
            | AllProperties
    ): AudioProperties | ImageProperties | VideoProperties | TextProperties | ShapeProperties | ContainerProperties | LottieProperties | AllProperties | null {
        const designerSettings = getTemplateData<Template['designerSettings']>('designerSettings');
        const defaultUnit = designerSettings.defaultUnit || UnitOptions.Percentages;

        if (layerProperties.x && layerProperties.x.unit) layerProperties.x.unit = defaultUnit;
        if (layerProperties.y && layerProperties.y.unit) layerProperties.y.unit = defaultUnit;

        return layerProperties;
    }

    /**
     * Overwrite the default properties.
     * @param layerProperties - The layer properties to overwrite.
     * @returns The layer properties with the overwritten values.
     */
    private static overwriteDefaultProperties(
        layerProperties:
            | AudioProperties
            | ImageProperties
            | VideoProperties
            | TextProperties
            | ShapeProperties
            | ContainerProperties
            | LottieProperties
            | AllProperties
    ): AudioProperties | ImageProperties | VideoProperties | TextProperties | ShapeProperties | ContainerProperties | LottieProperties | AllProperties | null {
        // Set the last used font in text styling.
        if ('textStyling' in layerProperties) {
            layerProperties.textStyling.normal = { ...layerProperties.textStyling.normal, ...FontHelpers.getLastUsedFont() };
        }

        return layerProperties;
    }

    /**
     * Remove the default properties that are the default in CSS.
     * So the default layer properties will become smaller to store.
     * @param layerProperties - Layer properties to remove default keys from.
     * @param layerType - The type layer.
     * @returns Layer properties with the removed keys.
     */
    static removeDefaultProperties(layerProperties: LayerProperties, layerType: Layer['type']): LayerProperties {
        let propertiesToRemove: string[] = [
            'canEdit',
            'horizontalAlign',
            'verticalAlign',
            'maxWidth',
            'maxHeight',
            'scale',
            'lockedSize',
            'rotation',
            'skew',
            'customCSS',
            'padding',
            'margin',
            'opacity',
            'blendMode',
            'display',
            'borderRadius',
            'border',
            'shadow',
            'shadowPlacement',
            'overflow',
            'lineHeight',
            'textBackground',
            'customClassname'
        ];

        switch (layerType) {
            case 'container':
                propertiesToRemove.push('align', 'justify', 'columnGap', 'rowGap');
                break;
            case 'text': {
                const textProperties = [
                    'letterSpacing',
                    'textAlignHorizontal',
                    'textAlignVertical',
                    'textDecoration',
                    'textDecorationStyle',
                    'textShadow',
                    'textBorder'
                ];
                propertiesToRemove.push(...textProperties.map((property) => `textStyling.normal.${property}`));
                break;
            }
            case 'image':
            case 'video':
                propertiesToRemove = propertiesToRemove.filter((property) => property !== 'overflow');
                break;
            case 'audio':
                propertiesToRemove.push('volume', 'loop');
                break;
        }

        const defaultProperties = this.getDefaultProperties(layerType);

        propertiesToRemove.forEach((key) => {
            const defaultProperty = get(defaultProperties, key);
            const property = get(layerProperties.properties, key);

            // If the property is the same as the default property, remove it.
            if (isEqual(property, defaultProperty)) {
                unset(layerProperties.properties, key);
            }
        });

        // TODO: fix the ts ignore when the layer properties are better typed.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.visibility;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.hover.properties.scale;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.hover.properties.translateX;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.hover.properties.translateY;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.hover.properties.rotation;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete layerProperties.hover.properties.opacity;

        return layerProperties;
    }

    /**
     * Merge the layer properties with the selected formats.
     * @param layerKey - The key of the layer.
     * @param frameType - The frame type.
     * @param layerProperties - The whole layer properties.
     * @param selectedFormats - The selected formats.
     * @returns The merged layer properties with keyframes.
     */
    static mergeLayerProperties = (
        layerKey: Layer['key'],
        frameType: View['frameType'] | undefined = getTemplateData<View['frameType']>('view.frameType'),
        currentFormats: Format['key'][],
        layerProperties?: Template['layerProperties'],
        generalProperties?: LayerProperties,
        formatProperties?: LayerProperties
    ): { layerProps: LayerProperties; formatOverwrites: string[] } => {
        const currentFormat = currentFormats[0];

        if (!layerProperties) {
            layerProperties = getTemplateData<Template['layerProperties']>('layerProperties', { clone: false });
        }

        if (layerProperties && !generalProperties) {
            generalProperties = get(layerProperties, `general.${frameType}.${layerKey}`) as LayerProperties;
        }

        if (layerProperties && !formatProperties) {
            formatProperties = get(layerProperties, `${currentFormat}.${frameType}.${layerKey}`) as LayerProperties;
        }

        if (generalProperties) {
            generalProperties = cloneDeep(generalProperties);
        }

        if (formatProperties) {
            formatProperties = cloneDeep(formatProperties);
        }

        if (!generalProperties) {
            throw new Error('No general properties found for layer ' + layerKey);
        }

        //TODO: DELETE AFTER WE CREATED A WRAPPER
        if (formatProperties && Array.isArray(formatProperties.properties)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formatProperties.properties;
        }

        const formatOverwrites: string[] = [];

        /**
         * Merge the animations.
         */
        if (formatProperties && currentFormat !== 'general') {
            const mergedAnimations = {};

            const formatVisibility = formatProperties?.visibility;

            if (formatVisibility) {
                formatOverwrites.push('visibility');
            }

            for (const animationKey in generalProperties.animations) {
                const generalAnimation = generalProperties.animations[animationKey];
                const formatAnimation = formatProperties?.animations?.[animationKey];

                if (formatAnimation === null) {
                    mergedAnimations[animationKey] = formatAnimation;
                    formatOverwrites.push(animationKey as AnimationOptions);
                } else if (formatAnimation && formatAnimation.length > 0) {
                    mergedAnimations[animationKey] = formatAnimation;
                } else if (generalAnimation && generalAnimation.length > 0) {
                    mergedAnimations[animationKey] = generalAnimation;
                }
            }

            for (const animationKey in formatProperties.animations) {
                const formatAnimation = formatProperties?.animations?.[animationKey];

                if (Object.keys(mergedAnimations).includes(animationKey)) {
                    formatOverwrites.push(animationKey as AnimationOptions);
                    continue;
                }

                if (formatAnimation === null) {
                    mergedAnimations[animationKey] = formatAnimation;
                    formatOverwrites.push(animationKey as AnimationOptions);
                } else if (formatAnimation && formatAnimation.length > 0) {
                    mergedAnimations[animationKey] = formatAnimation;
                    formatOverwrites.push(animationKey as AnimationOptions);
                }
            }

            const newLayerProps: LayerProperties = mergeWith(generalProperties, formatProperties, (general, format) => {
                // TODO: replace mergeWith with merge once we have a script to remove empty arrays.
                if (Array.isArray(format) && format.length === 0) {
                    return general;
                }
            });

            if (newLayerProps.visibility === undefined) {
                newLayerProps.visibility = TimelineHelpers.getDefaultVisibility();
            }

            newLayerProps.animations = mergedAnimations as LayerProperties['animations'];

            return { layerProps: newLayerProps, formatOverwrites: Array.from(new Set(formatOverwrites)) };
        }

        if (generalProperties.visibility === undefined) {
            generalProperties.visibility = TimelineHelpers.getDefaultVisibility();
        }

        /**
         * Remove empty animations from the general properties.
         */
        this.removeEmptyAnimations(generalProperties.animations);

        return { layerProps: generalProperties, formatOverwrites };
    };

    /**
     * Move format overwrites to general.
     * All layers and format styling.
     * @param formatKey - The key of the format to move the overwrites from.
     */
    static moveOverwritesToGeneral = (formatKey: Format['key']): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const generalProperties = getTemplateData<Template['layerProperties']['general']['main']>(`layerProperties.general.${frameType}`);
        const changes: MultiModel = [];

        Object.keys(generalProperties).forEach((layerKey) => {
            if (layerKey === 'properties') {
                const overwrites = getTemplateData<Template['layerProperties']['format']['main']>(`layerProperties.${formatKey}.${frameType}.properties`) ?? {};
                const newGeneralProperties = merge(generalProperties['properties'], overwrites);
                changes.push([`layerProperties.general.${frameType}.${layerKey}`, newGeneralProperties]);
            } else {
                const overwrites =
                    getTemplateData<Template['layerProperties']['format']['main']>(`layerProperties.${formatKey}.${frameType}.${layerKey}`) ?? {};
                const newGeneralProperties = merge(generalProperties[layerKey], overwrites);
                changes.push([`layerProperties.general.${frameType}.${layerKey}`, newGeneralProperties]);
            }
        });

        changes.push([`layerProperties.general.${frameType}`, generalProperties]);
        changes.push([`layerProperties.${formatKey}.${frameType}`, { properties: {} }]);

        TemplateDesignerStore.save(changes);
    };

    /**
     * Move the layer properties overwrites from a specific format and layer to general.
     * @param formatKeys - The format or array of formats to move the overwrites to.
     * @param layerKey - The key of the layer to move the overwrites from.
     */
    static moveLayerOverwritesToGeneral = (formatKey: Format['key'], layerKey: Layer['key']): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        const changes: MultiModel = [];
        const generalProperties: LayerProperties = getTemplateData<LayerProperties>(`layerProperties.general.${frameType}.${layerKey}`);
        const overwrites = getTemplateData<LayerProperties>(`layerProperties.${formatKey}.${frameType}.${layerKey}`) ?? {};
        const newGeneralProperties = merge(generalProperties, overwrites);
        changes.push([`layerProperties.general.${frameType}.${layerKey}`, newGeneralProperties]);
        changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}`, { properties: {} }]);

        TemplateDesignerStore.save(changes);
    };

    /**
     * Check if there are overwrites for the given format and if specified style or animations
     * @param formatKey key of the format to check
     * @param model model to check for overwrites either style or animation overwrites or undefined for both
     * @returns if there are overwrites for the given format and model
     */
    private static hasOverwrites = (formatKey: Format['key'], model?: 'properties' | 'animations'): boolean => {
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties', { clone: false });
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formatStyle = get(layerProperties, `${formatKey}.${frameType}.properties`, {});

        if (Object.keys(formatStyle).length > 0) return true;

        const formatStyling = get(layerProperties, `${formatKey}.${frameType}`, {});
        if (!formatStyling) return false;

        // Check every layer if it has overwrites.
        for (const layerKey of Object.keys(formatStyling)) {
            if (layerKey === 'properties') continue;

            const layerFormatProperties = get(layerProperties, `${formatKey}.${frameType}.${layerKey}`, {});
            if (Object.keys(layerFormatProperties).length === 0) return false;

            if (model) {
                const layerProperties = get(layerFormatProperties, `${model}`, {});
                if (layerProperties && Object.keys(layerProperties).length > 0) return true;
            } else {
                for (const key of Object.keys(layerFormatProperties)) {
                    const layerProperties = get(layerFormatProperties, `${key}`, {});
                    if (layerProperties && Object.keys(layerProperties).length > 0) return true;
                }
            }
        }

        return false;
    };

    /**
     * Returns if there are style overwrites for the given format
     * @param formatKey key of the format to check
     * @returns boolean if there are style overwrites for the given format
     */
    static hasPropertyOverwrites = (formatKey: Format['key']): boolean => {
        return this.hasOverwrites(formatKey, 'properties');
    };

    /**
     * Returns if there are animation overwrites for the given format
     * @param formatKey key of the format to check
     * @returns boolean if thee are animation overwrites for the given format
     */
    static hasAnimationOverwrites = (formatKey: Format['key']): boolean => {
        return this.hasOverwrites(formatKey, 'animations');
    };

    /**
     * Check if there are overwrites for the given format and layer.
     * @param formatKey - The key of the format to check.
     * @param layerKey - The key of the layer to check.
     * @param model - The model to check for overwrites.
     * @returns If there are overwrites for the given format and layer.
     */
    static hasLayerOverwrites = (formatKey: Format['key'], layerKey: Layer['key'], model: keyof LayerProperties): boolean => {
        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const layerFormatOverwrites = getTemplateData<Template['layerProperties']>(`layerProperties.${formatKey}.${frameType}.${layerKey}.${model}`) ?? {};
        return layerFormatOverwrites && Object.keys(layerFormatOverwrites).length > 0;
    };

    /**
     * Reset the layer properties of the selected formats.
     * @param layerKey - The key of the layer to reset.
     */
    static resetProperties(layerKey: Layer['key'], target: keyof LayerProperties): void {
        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');

        if (selectedFormats[0] === 'general') return;

        const changes: MultiModel = [];

        try {
            selectedFormats.forEach((format) => {
                const model = `layerProperties.${format}.${frameType}.${layer.key}`;
                const overwrites = getTemplateData<Template['layerProperties']['format']['main']>(model) ?? {};
                unset(overwrites, target);
                changes.push([model, overwrites]);
            });
        } catch {
            /* empty */
        }

        TemplateDesignerStore.save(changes);
    }

    /**
     * Reset format or array of formats for the current frametype.
     * @param formatKeys format or array of formats to be reset
     */
    static resetFormatOverwrites = (formatKeys: Format['key'] | Format['key'][]): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        if (typeof formatKeys === 'string') {
            formatKeys = [formatKeys];
        }

        const formatsToDelete: [string, object][] = [];

        formatKeys.forEach((formatKey) => {
            formatsToDelete.push([
                `layerProperties.${formatKey}.${frameType}`,
                {
                    properties: {}
                }
            ]);
        });

        TemplateDesignerStore.save(formatsToDelete);
    };

    /**
     * Reset layer or array of layers for the current frametype.
     * @param formatKeys format or array of formats to be reset
     * @param layerKey Key of layer to be reset
     */
    static resetLayerOverwrites = (formatKeys: Format['key'] | Format['key'][], layerKey: Layer['key']): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        if (typeof formatKeys === 'string') {
            formatKeys = [formatKeys];
        }

        const layersToReset: [string, object][] = [];

        formatKeys.forEach((formatKey) => {
            layersToReset.push([
                `layerProperties.${formatKey}.${frameType}.${layerKey}`,
                {
                    properties: {}
                }
            ]);
        });

        TemplateDesignerStore.save(layersToReset);
    };

    /**
     * Calculate the actual visibility of a layer, taking into account the visibility of its parents.
     * @param layer The layer to calculate the actual visibility for.
     * @param format The format to calculate the actual visibility for.
     * @param layerProperties The layer properties to calculate the actual visibility for.
     * @returns The actual visibility of the layer.
     */
    static calculateActualVisibility = (
        frameType: View['frameType'],
        layer: Layer['key'],
        format: Format['key'],
        layerProperties: Template['layerProperties']
    ): NonNullable<LayerProperties['visibility']> => {
        const { layerProps } = this.mergeLayerProperties(layer, frameType, [format], layerProperties);
        const layers = getTemplateData<Template['layers']['frameType']>(`layers.${frameType}`);
        if (!layers) return layerProps.visibility ?? TimelineHelpers.getDefaultVisibility();
        const layerPath = LayerHelpers.findLayerPath(layers, layer);

        if (!layerPath || !TemplateVersionHelpers.shouldHideChildren()) return layerProps.visibility ?? TimelineHelpers.getDefaultVisibility();

        const actualVisibility = layerProps.visibility ?? TimelineHelpers.getDefaultVisibility();
        layerPath.split('.').forEach((parent) => {
            const { layerProps } = this.mergeLayerProperties(parent, frameType, [format], layerProperties);
            const parentVisibility = layerProps.visibility ?? TimelineHelpers.getDefaultVisibility();
            actualVisibility[0] = Math.max(parentVisibility[0], actualVisibility[0]);
            actualVisibility[1] = Math.min(parentVisibility[1], actualVisibility[1]);
        });

        if (actualVisibility[0] > actualVisibility[1]) {
            actualVisibility[0] = 0;
            actualVisibility[1] = 0;
        }

        return actualVisibility;
    };

    /**
     * Gets the layer properties of the current layer
     * @returns The current layer properties or undefined if no layers are selected.
     */
    static getCurrentLayerProperties = (selectedLayer?: Layer, selectedFormats?: State['selectedFormats']): LayerProperties | undefined => {
        if (!selectedLayer) selectedLayer = getTemplateData<Layer>('state.selectedLayers')[0];
        if (!selectedLayer) return;

        if (!selectedFormats) selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');

        return this.mergeLayerProperties(selectedLayer?.key || '', undefined, selectedFormats, layerProperties).layerProps;
    };

    /**
     * Get the layer properties for one particular attribute for the given layer key
     * @param layerKey Key of the layer to get the property for
     * @param attribute The attribute to get. If not provided the whole properties object will be returned.
     * @param frameType The frameType to get the property for. If not provided the current frameType will be used.
     * @param includeNull If true null values will be included in the return object
     * @returns An object with per format a property of the layer
     */
    static getLayerProperty = (
        layerKey: Layer['key'],
        attribute?: string,
        frameType?: View['frameType'],
        includeNull = false
    ): { [key: FrameType['key']]: LayerProperties | unknown } => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const formatKeys = getTemplateData<Template['formats']>('formats', { clone: false }).map((format) => format.key);
        formatKeys.push('general');
        const layerProperties: { [key: Format['key']]: LayerProperties | unknown } = {};

        for (const key of formatKeys) {
            const properties = getTemplateData<LayerProperties>(`layerProperties.${key}.${frameType}.${layerKey}.properties`);
            if (properties) {
                if (attribute) {
                    const attributeValue = get(properties, attribute);
                    if (attributeValue !== undefined && attributeValue !== null) {
                        layerProperties[key] = attributeValue;
                    }
                } else {
                    layerProperties[key] = properties;
                }
            }

            if (includeNull && !layerProperties[key] && layerProperties[key] !== false) {
                layerProperties[key] = null;
            }
        }

        return layerProperties;
    };

    /**
     * Get all formats that only have a general styling for the given layer and property.
     * @param frameType - The frameType to get the formats for.
     * @param layer - The layer to get the formats for.
     * @param property - The property to get the formats for.
     * @returns An array with the format keys that only have general styling for the given layer and property.
     */
    static getFormatsWithGeneralStyling = (frameType: View['frameType'] | undefined, layer: Layer, property: string): Format['key'][] => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        const formats = getTemplateData<Template['formats']>('formats', { clone: false });

        const formatsWithGeneralStyling: Format['key'][] = [];
        formats.forEach((format) => {
            const value = getTemplateData<unknown>(`layerProperties.${format.key}.${frameType}.${layer.key}.properties.${property}`, { clone: false });
            if (value === undefined) formatsWithGeneralStyling.push(format.key);
        });

        return formatsWithGeneralStyling;
    };

    /**
     * Remove empty animations from the given animations object.
     * @param animations - The animations object to remove the empty animations from.
     */
    static removeEmptyAnimations = (animations: LayerProperties['animations'], removeNull = false): void => {
        for (const animationKey in animations) {
            const animation = animations[animationKey];
            if (animation === null && !removeNull) unset(animations, animationKey);
            if (animation && animation.length > 0) continue;
            delete animations[animationKey];
        }
    };
}

export { LayerPropertiesHelpers };
